import React from "react"
import * as PropTypes from "prop-types"

const Header = ({ siteTitle, disabled }) => (
  <section
    className={
      "slice slice-lg bg-gradient-primary overflow-hidden h-50" +
      (disabled ? " d-none d-md-block" : "")
    }
  >
    <div className="bg-absolute-cover bg-size--contain d-flex align-items-center">
      <figure className="w-100 d-none d-lg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1500 820"
          className="injected-svg svg-inject"
        >
          <path
            className="fill-cyan"
            d="M 177.67 511.271 L 144.933 477.895 C 141.713 474.613 141.713 469.414 144.933 466.132 L 177.67 432.758 C 180.89 429.474 185.989 429.474 189.209 432.758 L 221.946 466.132 C 225.167 469.414 225.167 474.613 221.946 477.895 L 189.209 511.271 C 186.257 514.553 180.89 514.553 177.67 511.271 Z"
          />
          <circle className="fill-pink" cx="1209.162" cy="110.471" r="34.859" />
          <path
            className="fill-blue"
            d="M 49.669 -9.998 C 51.03 -13.511 54.16 -16.03 57.882 -16.608 L 92.738 -22.023 C 96.459 -22.602 100.206 -21.149 102.568 -18.217 L 124.686 9.261 C 127.047 12.196 127.665 16.166 126.305 19.679 L 113.567 52.571 C 112.206 56.084 109.075 58.604 105.354 59.184 L 70.498 64.599 C 66.777 65.177 63.029 63.727 60.668 60.791 L 38.55 33.314 C 36.189 30.378 35.571 26.408 36.931 22.895 L 49.669 -9.998 Z"
          />
          <path
            className="fill-pink"
            d="M 1032.318 376.476 C 1033.264 374.034 1035.44 372.282 1038.028 371.88 L 1062.261 368.116 C 1064.848 367.713 1067.453 368.723 1069.095 370.762 L 1084.472 389.865 C 1086.114 391.906 1086.544 394.666 1085.598 397.108 L 1076.742 419.976 C 1075.796 422.418 1073.619 424.17 1071.032 424.574 L 1046.799 428.338 C 1044.212 428.74 1041.606 427.732 1039.965 425.691 L 1024.588 406.588 C 1022.946 404.547 1022.517 401.787 1023.462 399.344 L 1032.318 376.476 Z"
          />
          <path
            className="fill-purple"
            d="M 1396.93 665.688 C 1399.95 657.893 1406.894 652.305 1415.153 651.022 L 1492.489 639.008 C 1500.744 637.723 1509.057 640.947 1514.298 647.453 L 1563.372 708.418 C 1568.611 714.931 1569.981 723.739 1566.965 731.533 L 1538.703 804.512 C 1535.683 812.306 1528.736 817.897 1520.48 819.184 L 1443.144 831.199 C 1434.887 832.481 1426.573 829.264 1421.334 822.75 L 1372.26 761.786 C 1367.022 755.272 1365.65 746.463 1368.668 738.668 L 1396.93 665.688 Z"
          />
          <path
            className="fill-purple"
            d="M 264.129 775.914 C 265.075 773.472 267.251 771.72 269.839 771.318 L 294.072 767.554 C 296.659 767.151 299.264 768.161 300.906 770.2 L 316.283 789.303 C 317.925 791.344 318.355 794.104 317.409 796.546 L 308.553 819.414 C 307.607 821.856 305.43 823.608 302.843 824.012 L 278.61 827.776 C 276.023 828.178 273.417 827.17 271.776 825.129 L 256.399 806.026 C 254.757 803.985 254.328 801.225 255.273 798.782 L 264.129 775.914 Z"
          />
          <circle
            className="fill-cyan"
            strokeWidth="3"
            strokeMiterlimit="10"
            cx="745.931"
            cy="783.228"
            r="25.344"
          />
          <path
            className="fill-purple"
            d="M 929.895 57.225 C 930.389 55.951 931.524 55.036 932.875 54.826 L 945.523 52.862 C 946.873 52.652 948.232 53.179 949.089 54.243 L 957.115 64.213 C 957.972 65.278 958.196 66.719 957.702 67.993 L 953.08 79.928 C 952.587 81.203 951.45 82.117 950.1 82.328 L 937.453 84.293 C 936.103 84.502 934.742 83.976 933.886 82.911 L 925.86 72.941 C 925.004 71.876 924.78 70.435 925.273 69.16 L 929.895 57.225 Z"
          />
        </svg>
      </figure>
    </div>
    <div className="container d-flex justify-content-center align-items-center h-100">
      <h1 className="display-3 text-white zindex-100">{siteTitle}</h1>
    </div>
  </section>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  disabled: PropTypes.bool,
}

Header.defaultProps = {
  siteTitle: "",
  disabled: false,
}

export default Header
