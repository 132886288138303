import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import Picture from "../images/blog-post.jpg"
import ScrollAnim from "react-animate-on-scroll"

const BlogItem = ({ info }) => {
  const MainPicture =
    info.featured_media === null ? Picture : info.featured_media.source_url
  return (
    <div className="col-md-6 col-lg-4" key={info.id}>
      <ScrollAnim
        animateIn="fadeIn"
        duration="0.5"
        animateOnce="true"
        offset="120"
      >
        <Link
          className="card hover-shadow-lg hover-translate-y-n10 border-0 shadow-lg"
          to={"/" + info.slug}
        >
          <div
            style={{
              height: "12rem",
              backgroundSize: "cover",
              backgroundImage: "url(" + MainPicture + ")",
              backgroundPosition: "center",
            }}
          />
          <div className="card-body py-5 text-center">
            <h5
              className="d-block h5 lh-150"
              dangerouslySetInnerHTML={{ __html: info.title }}
            />
            <p>{info.author.name}</p>
            <h6 className="text-muted mt-2 mb-0">{info.date}</h6>
          </div>
        </Link>
      </ScrollAnim>
    </div>
  )
}

BlogItem.propTypes = {
  info: PropTypes.object,
}

BlogItem.defaultProps = {
  info: [],
}

export default BlogItem
