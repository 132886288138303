import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogItem from "../components/blog-item"
import Header from "../components/header"

import Picture from "../images/blog-post.jpg"
import { graphql } from "gatsby"

class BlogPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      postCount: 9,
    }
    this.handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount() {
    window.addEventListener(`scroll`, this.handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener(`scroll`, this.handleScroll)
  }
  handleScroll() {
    const body = document.body,
      html = document.documentElement
    const height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )
    const result = height - window.scrollY
    if (result < 1200) {
      this.setState({ postCount: this.state.postCount + 9 })
    }
  }

  render() {
    const { data } = this.props
    return (
      <Layout>
        <SEO
          title={"Ajankohtaista"}
          description={"Pysy ajantasalla ebrandin ajankohtaisista asioista."}
          image={Picture}
        />
        <Header siteTitle={"Ajankohtaista"} />
        <div className="container slice slice-lg pb-9">
          <div className={"row d-flex justify-content-between"}>
            {data.allWordpressPost.edges
              .slice(0, this.state.postCount)
              .map(({ node }) => (
                <BlogItem info={node} key={node.id} />
              ))}
          </div>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  {
    allWordpressPost {
      edges {
        node {
          id
          date(formatString: "D.M.YYYY")
          title
          content
          slug
          author {
            name
          }
          featured_media {
            source_url
          }
        }
      }
    }
  }
`

export default BlogPage
